<template>
  <div v-if="value" ref="el" :id="value">
    <span v-if="show" class="relative flex h-2 w-2">
      <span
        class="absolute inline-flex h-full w-full animate-ping rounded-full bg-info/40 opacity-75"
      ></span>
      <span class="relative inline-flex h-2 w-2 rounded-full bg-info"></span>
    </span>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{ value: string | null }>();

  const el = ref();
  const show = ref(false);

  const scrollIntoView = () => {
    show.value = false;
    if (location.hash === "#" + props.value) {
      show.value = true;
      setTimeout(() => {
        (el.value as HTMLDivElement).scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        // const p = (el.value as HTMLDivElement).parentElement?.parentElement;
        // if (p) {
        //   let cl = p.className;
        //   if (!cl.includes("animate-flash")) {
        //     const cl2 = cl + " animate-flash";
        //     p.className = cl2;
        //     setTimeout(() => (p.className = cl), 2000);
        //   }
        // }
      }, 200);
    }
  };

  onMounted(() => scrollIntoView());
  onActivated(() => scrollIntoView());
  onUpdated(() => scrollIntoView());
</script>

<style>
  tr.animate-flash > * {
    background-color: transparent !important;
  }
</style>
