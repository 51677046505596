<template>
  <div v-if="value" class="flex flex-row flex-wrap space-x-2">
    <div v-for="(item, i) in value">
      <component class="inline-block" :is="inferView(item)" :value="item" />
      <span v-if="i < value.length - 1">,</span>
    </div>
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script lang="ts">
  export default {
    name: "WBValueArray"
  };
</script>

<script setup lang="ts">
  defineProps<{
    value?: Array<any>;
  }>();
</script>
