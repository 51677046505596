<template>
  <NuxtLayout>
    <NuxtPage :keepalive="{ max: 2 }" />
  </NuxtLayout>
</template>

<script setup lang="ts">
  import Vue3EasyDataTable from "vue3-easy-data-table";
  import "vue3-easy-data-table/dist/style.css";
  import { useSubscriptionStore } from "@/stores/subscription";
  const themeUrl = ref("");

  useHead({
    titleTemplate: (titleChunk) => {
      const titleBase = "Policy Center";
      return titleChunk ? `${titleChunk} @ ${titleBase}` : titleBase;
    },
    meta: [
      // {
      //   "http-equiv": "content-security-policy",
      //   content: "default-src 'self'; style-src 'self' 'unsafe-inline';"
      // }
    ],
    link: [{ rel: "stylesheet", href: themeUrl }],
    htmlAttrs: {
      lang: "en",
      "data-theme": "wb-theme-light-v4"
    }
  });

  (async () => {
    const subscriptionInfo = await useSubscriptionStore().initSubscription();
    themeUrl.value = useTenantAssetThemeUrl(subscriptionInfo?.uuid!);
  })();

  useNuxtApp().vueApp.component("EasyDataTable", Vue3EasyDataTable);
</script>
