<template>
  <div
    v-if="value && value.length === 10"
    class="flex gap-2 items-center font-normal min-w-0"
  >
    {{ useDateFormat(value) }}
    <span v-if="hasTimeAgo" class="text-xs text-base-content/50">
      ({{ timeAgo?.value }})
    </span>
  </div>
  <div
    v-else-if="value && value.length > 10"
    class="truncate gap-2 items-center"
  >
    {{ useDateTimeFormat(value) }}

    <span v-if="hasTimeAgo" class="text-xs text-base-content/50">
      ({{ timeAgo?.value }})
    </span>
  </div>
  <div
    v-else-if="!value"
    class="h-5 w-full grow animate-pulse bg-base-200"
  ></div>
  <div v-else>n/a</div>
</template>

<script lang="ts">
  export default {
    name: "WBValueDateTime"
  };
</script>

<script setup lang="ts">
  import { useTimeAgo } from "@vueuse/core";
  import type { ViewValueProps } from "@/utils";

  const props = withDefaults(defineProps<ViewValueProps<string>>(), {
    hasTimeAgo: true
  });

  const timeAgo = computed(() => {
    if (props.value) {
      return useTimeAgo(props.value);
    }
  });
</script>
