<template>
  <div id="container" class="flex justify-center dropdown dropdown-end">
    <details id="details" class="dropdown dropdown-end">
      <summary
        @click="toggleDropdown"
        ref="toggleButton"
        class="btn btn-ghost rounded hover:bg-primary-hover h-6 w-6 p-8"
      >
        <Icon id="icon" name="ph:dots-three-outline-vertical-fill" />
      </summary>

      <ul
        tabindex="0"
        :style="styleActions"
        id="list"
        class="text-neutral font-medium bg-base-100 border border-base-300 p-2 shadow-md menu dropdown-content z-[2] rounded w-52"
      >
        <!-- <li>
          <a
            target="_blank"
            :href="`/${useRoute().params.tenant}/d/file/download/${accountingNote?.pdfFileRef}`"
            >View Invoice</a
          >
        </li> -->
        <li>
          <a
            :href="`/${useRoute().params.tenant}/d/file/download/${accountingNote?.csvFileRef}`"
            >View Analysis</a
          >
        </li>
        <li
          :class="{
            'pointer-events-none opacity-50': props.accountingNote?.finalized
          }"
        >
          <a @click="actions.finalize(accountingNote)">Finalize</a>
        </li>
        <hr class="my-2" />
        <li
          :class="{
            'pointer-events-none opacity-50': props.accountingNote?.finalized
          }"
        >
          <a @click="actions.delete(accountingNote?.id)">Delete Invoice</a>
        </li>
      </ul>
    </details>
  </div>
</template>

<script setup lang="ts">
  import type { AccountingNote } from "~/graphql";

  const props = defineProps<{
    id?: string;
    accountingNote?: AccountingNote;
    actions?: any;
  }>();

  type ActionsPosition = {
    left?: string;
    top?: string;
  };

  const toggleButton = ref();
  const styleActions = ref<ActionsPosition>({});

  const toggleDropdown = () => {
    const dropdownIconContainerWidth =
      document.getElementById("container")!.clientWidth;

    const button = toggleButton.value.getBoundingClientRect();
    const buttonTop = button.top;
    const buttonLeft = button.left;

    const list = document.getElementById("list");
    const listWidth = list!.clientWidth;
    const listHeight = list!.clientHeight;

    const gap = 4;

    styleActions.value.left = `${Math.floor(buttonLeft - listWidth + dropdownIconContainerWidth)}px`;
    styleActions.value.top = `${Math.floor(buttonTop - listHeight - gap)}px`;

    window.onscroll = () => {
      (document.activeElement as HTMLElement).blur();
    };
  };
</script>

<style scoped>
  .dropdown-content {
    position: fixed !important;
  }

  .btn {
    padding: 0;
    min-height: 0;
  }

  :where(
      .menu li:not(.menu-title, .disabled) > *:not(ul, details, .menu-title)
    ):not(.active, .btn):hover {
    border-radius: 4px;
    color: oklch(var(--bc));
    background-color: oklch(var(--b2));
  }
</style>
