import mitt from "mitt";

export enum NotificationType {
  info,
  success,
  warning,
  error
}

export interface NotificationEvent {
  id?: string;
  type: NotificationType;
  message: string;
  ts?: Date;
}

export type ApplicationEvents = {
  "notification:show": [NotificationEvent];
};

export default defineNuxtPlugin(() => {
  // Then we just inform mitt about our event types
  const emitter = mitt<ApplicationEvents>();

  return {
    provide: {
      event: emitter.emit, // Will emit an event
      listen: emitter.on // Will register a listener for an event
    }
  };
});
