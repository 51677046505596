<template>
  <div v-if="value !== undefined">
    <div class="flex min-w-0 items-center justify-between font-sans w-full">
      <span class="truncate">{{ value }}</span>

      <div v-if="isSupported">
        <button
          @click.stop.prevent="copy(value)"
          tabindex="0"
          class="btn btn-ghost btn-sm hover:bg-transparent"
          v-if="value !== null"
        >
          <span v-if="!copied">
            <Icon name="mdi:files" width="14" height="16"></Icon>
          </span>
          <span v-else>
            <Icon name="fa:check" color="#cf41a4" width="16" height="16"></Icon>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script lang="ts">
  export default {
    name: "WBValueString"
  };
</script>

<script setup lang="ts">
  import { useClipboard } from "@vueuse/core";

  const props = defineProps<{
    value?: string;
  }>();

  const source = ref("");
  const { copy, copied, isSupported } = useClipboard({ source });
</script>
