<template>
  <div v-if="_value !== undefined" class="truncate">
    {{ _value }}
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script setup lang="ts">
  import type { Person } from "~/graphql";

  const props = defineProps<{
    value?: Person;
  }>();

  const _value = computed(() => {
    if (!props.value) return "";

    const { firstName, lastName } = props.value;

    const name =
      `${lastName ?? ""} ${firstName ? firstName.charAt(0).toUpperCase() + "." : ""}`.trim();

    return name;
  });
</script>
