import { defineStore } from "pinia";
import type { SubscriptionInfo } from "@/utils";
import { getCDNUrl } from "@/utils";

export const useSubscriptionStore = defineStore("subscription", () => {
  const subscriptionInfo = ref<SubscriptionInfo | null>(null);

  async function initSubscription() {
    if (!subscriptionInfo.value?.uuid) {
      const slDomain = getSubscriptionName();
      const resp = await useFetch<SubscriptionInfo>(
        `${getCDNUrl()}/subscription/${slDomain}.json`
      );
      subscriptionInfo.value = resp.data.value;
    }

    return subscriptionInfo.value;
  }

  return { subscriptionInfo, initSubscription };
});
