import { NotificationType } from "~~/plugins/1.eventBus";

let executing = false;

export const useTenantCheckin = async (tenant?: string, redirect = true) => {
  if (executing) return true;
  executing = true;

  if (!tenant) {
    //this is not updated while in layout phase
    // tenant = useRoute().params.tenant as string;
    tenant = location.pathname.split("/")[1];
  }
  if (!tenant) {
    executing = false;
    return false;
  }

  const resumePath = location.href;
  const CHECKIN_URL = `/${tenant}/d/login?resume_path=${resumePath}`;

  try {
    await $fetch(CHECKIN_URL, {
      headers: { "X-Requested-With": "JavaScript" }
    });
  } catch (error: any) {
    const statusCode = error?.statusCode;
    if (statusCode) {
      if ((statusCode === 499 || statusCode === 401) && redirect) {
        location.assign(CHECKIN_URL);
      } else if (statusCode >= 500 && statusCode < 600) {
        const { $event } = useNuxtApp();
        $event("notification:show", [
          {
            type: NotificationType.error,
            message:
              "Authentication error occurred. Please contact your administrator."
          }
        ]);
      }
      return statusCode;
    }
  }
  setTimeout(() => (executing = false), 3000);

  return true;
};
