import { useTenantCheckin } from "@/composables/checkin";
import type { ErrorResponse } from "@nuxtjs/apollo";
import { NotificationType } from "@/plugins/1.eventBus";
import { provideApolloClient } from "@vue/apollo-composable";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $event } = useNuxtApp();

  const hook = (error: ErrorResponse) => {
    if (error.networkError) {
      const { statusCode } = error.networkError as any;

      if (statusCode === 499) {
        useTenantCheckin();
      } else if (statusCode === 500) {
        console.error("Apollo Network Error", error);
        $event("notification:show", [
          {
            type: NotificationType.error,
            message:
              "Network error occured. Please check your connectivity and try again."
          }
        ]);
      }
    } else if (error.graphQLErrors) {
      console.error("Apollo Application Error", error);
      $event("notification:show", [
        {
          type: NotificationType.error,
          message:
            "Application error occurred. Please contact your administrator."
        }
      ]);
    } else {
      console.error("Apollo Unexpected Error", error);
      $event("notification:show", [
        {
          type: NotificationType.error,
          message:
            "Unexpected error occurred. Please contact your administrator."
        }
      ]);
    }
  };

  nuxtApp.hook("apollo:error", hook);
  provideApolloClient(useApollo()?.clients?.default!);
});
