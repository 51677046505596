<template>
  <div
    v-if="value == undefined"
    class="h-5 w-full grow animate-pulse bg-base-200"
  ></div>
  <div
    v-else-if="value"
    class="badge badge-sm rounded-md py-2.5 px-2 font-normal border-none badge-success"
  >
    {{ getLabel }}
  </div>
  <div
    v-else
    class="badge badge-sm rounded-md py-2.5 px-2 font-normal border-none badge-error"
  >
    {{ getLabel }}
  </div>
</template>

<script lang="ts">
  export default {
    name: "WBValueBoolean"
  };
</script>

<script setup lang="ts">
  import type { ViewValueProps } from "@/utils";

  const props = defineProps<ViewValueProps<boolean>>();

  const getLabel = computed(() => {
    if (Array.isArray(props.valueLabels)) {
      return props.valueLabels[props.value ? 1 : 0];
    } else {
      return props.value;
    }
  });
</script>
