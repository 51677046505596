<template>
  <div v-if="_value !== undefined" class="truncate">
    {{ _value }}
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script setup lang="ts">
  import type { Person } from "~/graphql";

  const props = defineProps<{
    value?: Person;
  }>();
  const _value = computed(() => {
    return `${props.value?.firstName} ${props.value?.lastName}`;
  });
</script>
