<template>
  <div v-if="value !== undefined" class="truncate">
    {{ value }}
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script lang="ts">
  export default {
    name: "WBValueString"
  };
</script>

<script setup lang="ts">
  const props = defineProps<{
    value?: any;
  }>();
</script>
