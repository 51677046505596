import dayjs from "dayjs";

type DateInput = string | number | Date | undefined;

export const useDateTimeFormat = (dt: DateInput) => {
  const djs = dayjs(dt);
  if (djs.year() == new Date().getFullYear()) {
    return djs.format("MMM DD, H:mm:ss");
  }
  return djs.format("MMM DD, YYYY H:mm:ss");
};

export const useDateFormat = (dt: DateInput) => {
  const djs = dayjs(dt);
  if (djs.year() == new Date().getFullYear()) {
    return djs.format("MMM DD");
  }
  return djs.format("MMM DD, YYYY");
};

export const useTimeFormat = (dt: DateInput) => {
  return dayjs(dt).format("H:mm:ss");
};
