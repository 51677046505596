export const isArray = (val: any) => {
  return Array.isArray(val) && typeof val[0] !== "object";
};

export const isBoolean = (val: any) => {
  return typeof val === "boolean";
};

export const isNumber = (val: any) => {
  return typeof val === "number";
};

export const isDateTime = (val: any) => {
  //nosonar
  const ISO_8601 =
    /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;
  if (val && val.length >= 10) {
    return ISO_8601.test(val);
  }
  return false;
};

export const filterObject = (
  selectedKeys: Array<ViewValue>,
  kv?: Record<string, any> | null
) => {
  if (!kv) return null;

  return selectedKeys.reduce((p, c) => {
    return Object.assign(p, { [c.key]: resolveValue(c.key, kv) });
  }, {});
};

export const resolveValue = (path: string, obj: Record<string, any>) => {
  if (!path) return null;
  return path.split(".").reduce((p, c) => (p ? p[c] : null), obj);
};
