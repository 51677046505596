<template>
  <div v-if="value !== undefined" class="flex items-center gap-3 font-sans">
    <div v-if="value.network === 'visa'">
      <Icon name="fontisto:visa" width="23" height="19"></Icon>
    </div>
    <div v-else-if="value.network === 'mastercard'">
      <Icon name="fontisto:visa" width="23" height="19"></Icon>
    </div>

    <div v-else class="flex items-center gap-3">
      <Icon name="bytesize:creditcard" width="23" height="19"></Icon>
      OTHER
    </div>

    <div>
      <span>{{ value.masked }}</span>
      <span v-if="value.threeDSecure === true">
        <span class="mx-1 text-base-content/50">|</span>3DS</span
      >
      <span v-if="value.country">
        <span class="mx-1 text-base-content/50">|</span
        >{{ value.country }}</span
      >
    </div>
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script lang="ts">
  export default {
    name: "WBValueCreditCard"
  };
</script>

<script setup lang="ts">
  const props = defineProps<{
    value?: any;
  }>();
</script>
