<template>
  <div class="flex">
    <div class="badge badge-sm rounded-md py-2.5 px-2" :class="badgeStyle">
      <span>{{ capitalizeValue }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { AccountingNoteStatus } from "~/graphql";

  const props = defineProps<{
    value?: string;
  }>();

  const badgeStyle = computed(() =>
    props.value === AccountingNoteStatus.Due ? "badge-warning" : "badge-success"
  );

  const capitalizeValue = computed(() => {
    return (
      props.value?.toLocaleLowerCase().charAt(0).toUpperCase() +
      props.value?.slice(1).toLowerCase()!
    );
  });
</script>
