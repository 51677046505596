<template>
  <div>{{ value }}</div>
</template>

<script lang="ts">
  export default {
    name: "WBValueRaw"
  };
</script>

<script setup lang="ts">
  defineProps<{
    value?: string;
  }>();
</script>
