import { useSessionStore } from "./../stores/session";
import {
  IdentifierOperationType,
  ScalarOperationType,
  ArrayOperationType
} from "@/graphql";
import type { NuxtLinkProps } from "nuxt/app";

import type { Header } from "vue3-easy-data-table";
import WBValueString from "~~/components/WBValueString.vue";
import WBValueAssets from "~~/components/WBValueAssets.vue";
import WBValueDateTime from "~~/components/WBValueDateTime.vue";
import WBValueArray from "~~/components/WBValueArray.vue";
import WBValueBoolean from "~~/components/WBValueBoolean.vue";
import WBValueNumber from "~~/components/WBValueNumber.vue";
import WBValueHash from "~~/components/WBValueHash.vue";
import WBValueRaw from "~~/components/WBValueRaw.vue";
import WBValueFullName from "~~/components/WBValueFullName.vue";
import WBValueStatus from "~~/components/WBValueStatus.vue";
import WBValueInvoiceStatus from "~/components/WBValueInvoiceStatus.vue";
import WBValueClaimStatus from "~/components/WBValueClaimStatus.vue";
import WBValueActions from "~~/components/WBValueActions.vue";
import WBValueCheckbox from "~~/components/WBValueCheckbox.vue";
import WBValuePerson from "~~/components/WBValuePerson.vue";
import WBValueStringClipboard from "~~/components/WBValueStringClipboard.vue";
import WBValueCreditCard from "~~/components/WBValueCreditCard.vue";
import WBValueNavigationLink from "~~/components/WBValueNavigationLink.vue";
import dayjs from "dayjs";

export type SubscriptionInfo = {
  uuid: string;
  name: string;
  logo?: string;
};

export enum WBView {
  WBValueString = "WBValueString",
  WBValueDateTime = "WBValueDateTime",
  WBValueArray = "WBValueArray",
  WBValueAssets = "WBValueAssets",
  WBValueBoolean = "WBValueBoolean",
  WBValueNumber = "WBValueNumber",
  WBValueHash = "WBValueHash",
  WBValueFullName = "WBValueFullName",
  WBValueStatus = "WBValueStatus",
  WBValueInvoiceStatus = "WBValueInvoiceStatus",
  WBValueClaimStatus = "WBValueClaimStatus",
  WBValueActions = "WBValueActions",
  WBValueCheckbox = "WBValueCheckbox",
  WBValuePerson = "WBValuePerson",
  WBValueStringClipboard = "WBValueStringClipboard",
  WBValueCreditCard = "WBValueCreditCard",
  WBValueNavigationLink = "WBValueStringNavigationLink"
}

//Holds the extra hints that the view component can consider to render the value
type ViewHints = {
  currency?: string; //value of currency
  currencyPath?: string; //path to currency value (eg, policy.oricing.currency)
  hasTimeAgo?: boolean; //considered by WBValueDateTime
  personType?: "policyHolder" | "primaryInsured";
  align?: "left" | "right" | "center";
  width?: number;
  valueLabels?: Array<string>;
  goto?: NuxtLinkProps["to"];
  actions?: any;
  //you can add more view hints here ...
};

//makes another stucture aware of wbview components
type ViewAware = ViewHints & {
  view: WBView;
};

//Similar to EasyDataTable's Header structure for non data table views
type ViewValueBase = {
  label?: string; //as 'text' in Header
  key: string; // as 'value' in Header
};

export type ViewHeader = Header & ViewAware; //for data table
export type ViewValue = ViewValueBase & ViewAware; //for all other

//To be used as props for all view components
export type ViewValueProps<T> = { value: T } & ViewHints;

export const ROWS_PER_PAGE = 20;

export const resolveView = (view?: WBView) => {
  switch (view) {
    case WBView.WBValueDateTime:
      return WBValueDateTime;
    case WBView.WBValueArray:
      return WBValueArray;
    case WBView.WBValueBoolean:
      return WBValueBoolean;
    case WBView.WBValueNumber:
      return WBValueNumber;
    case WBView.WBValueHash:
      return WBValueHash;
    case WBView.WBValueString:
      return WBValueString;
    case WBView.WBValueStatus:
      return WBValueStatus;
    case WBView.WBValueFullName:
      return WBValueFullName;
    case WBView.WBValueInvoiceStatus:
      return WBValueInvoiceStatus;
    case WBView.WBValueClaimStatus:
      return WBValueClaimStatus;
    case WBView.WBValueActions:
      return WBValueActions;
    case WBView.WBValueCheckbox:
      return WBValueCheckbox;
    case WBView.WBValuePerson:
      return WBValuePerson;
    case WBView.WBValueStringClipboard:
      return WBValueStringClipboard;
    case WBView.WBValueCreditCard:
      return WBValueCreditCard;
    case WBView.WBValueNavigationLink:
      return WBValueNavigationLink;
    case WBView.WBValueAssets:
      return WBValueAssets;
    default:
      return WBValueRaw;
  }
};

export const inferView = (value: any) => {
  if (isDateTime(value)) {
    return WBValueDateTime;
  } else if (isArray(value)) {
    return WBValueArray;
  } else if (isBoolean(value)) {
    return WBValueBoolean;
  } else if (isNumber(value)) {
    return WBValueNumber;
  }

  return WBValueString;
};

export const getOIDCProviderUrl = () => {
  const { tenant } = useRoute().params;

  const session = useSessionStore();
  if (!session.user?.oidcServerUrl) return;

  let url = session.user.oidcServerUrl;
  if (!url.endsWith("/")) url += "/";
  return `${url}realms/${tenant}/`;
};

export const getSubscriptionName = () => {
  return window.location.hostname.split(".")[0];
};

export const getCDNUrl = () => {
  const parts = location.hostname.split(".");
  if (parts.length !== 3) return null;

  let tld = parts[1] + "." + parts[2];
  if (tld.includes("local")) {
    tld = "wallbid.io";
  }

  const sld = parts[0];
  const site = sld.split("-")[0];

  if ("develop" === site) {
    return "https://develop-cdn." + tld;
  } else if ("staging" === site) {
    return "https://staging-cdn." + tld;
  }

  return "https://cdn." + tld;
};

export enum DATA_TYPE {
  STRING,
  INTEGER,
  FLOAT,
  DATE,
  DATETIME,
  BOOLEAN
}

export interface FilterStrategy<T extends operationTypes> {
  buildFilter: (
    value: any,
    operator: T
  ) => {
    fieldName: string;
    filterValue: {
      operation: T;
      elements: Array<any>;
    };
  };

  labels: operationTypes[];
  label?: operationTypes;
}

export enum FILTER_TYPE {
  IDENTIFIER_FILTER = "IDENTIFIER_FILTER",
  SCALAR_FILTER = "SCALAR_FILTER",
  ARRAY_FILTER = "ARRAY_FILTER"
}

export const IdentifierFilter = (
  fieldName: string
): FilterStrategy<IdentifierOperationType> => {
  const buildFilter = (value: string[], operator: IdentifierOperationType) => {
    return {
      fieldName,
      filterValue: {
        operation: operator,
        elements: value
      }
    };
  };

  return {
    buildFilter,
    labels: [
      IdentifierOperationType.Equals,
      IdentifierOperationType.NotEquals,
      IdentifierOperationType.EqualsNull,
      IdentifierOperationType.EqualsNotNull
    ],
    label: IdentifierOperationType.Equals
  };
};

export const ScalarFilter = (
  fieldName: string,
  dataType: DATA_TYPE
): FilterStrategy<ScalarOperationType> => {
  const buildFilter = (value: any[], operator: ScalarOperationType) => {
    const elements = value?.map((e) => {
      if (dataType === DATA_TYPE.DATE || dataType === DATA_TYPE.DATETIME)
        return dayjs(e);
      else return e;
    });
    return {
      fieldName: fieldName,
      filterValue: {
        operation: operator,
        elements: elements
      }
    };
  };

  return {
    buildFilter,
    labels: [
      ScalarOperationType.Equals,
      ScalarOperationType.NotEquals,
      ScalarOperationType.Between,
      ScalarOperationType.GreaterEqualsThan,
      ScalarOperationType.GreaterThan,
      ScalarOperationType.LessEqualsThan,
      ScalarOperationType.LessThan,
      ScalarOperationType.EqualsNull,
      ScalarOperationType.EqualsNotNull
    ]
  };
};

export const ArrayFilter = (
  fieldName: string
): FilterStrategy<ArrayOperationType> => {
  const buildFilter = (value: string[], operator: ArrayOperationType) => {
    return {
      fieldName: fieldName,
      filterValue: {
        operation: operator,
        elements: value
      }
    };
  };
  return {
    buildFilter,
    labels: [ArrayOperationType.Contains, ArrayOperationType.NotContains]
  };
};
export type operationTypes =
  | IdentifierOperationType
  | ScalarOperationType
  | ArrayOperationType;

export const CreateFilter = (
  fieldType: FILTER_TYPE,
  fieldName: string,
  dataType: DATA_TYPE
): FilterStrategy<operationTypes> => {
  let strategy: (fieldName: string, dataType: DATA_TYPE) => any;

  switch (fieldType) {
    case FILTER_TYPE.IDENTIFIER_FILTER:
      strategy = IdentifierFilter;
      break;
    case FILTER_TYPE.SCALAR_FILTER:
      strategy = ScalarFilter;
      break;
    case FILTER_TYPE.ARRAY_FILTER:
      strategy = ArrayFilter;
      break;
  }
  return strategy(fieldName, dataType);
};

export const addFilter = <T>(filterItem: any, filter: T) => {
  let _filter = filterItem.value.getFilter();
  if (
    _filter?.filterValue?.operation &&
    ((_filter?.filterValue?.elements &&
      _filter?.filterValue?.elements.length > 0) ||
      isEmptyOrNotEmptyOperation(_filter?.filterValue?.operation))
  ) {
    (filter as Record<string | number, any>)[_filter.fieldName] =
      _filter.filterValue;
  }
};

export const isEmptyOrNotEmptyOperation = (option: string): boolean => {
  const validOptions: string[] = [
    IdentifierOperationType.EqualsNull,
    ScalarOperationType.EqualsNull,
    IdentifierOperationType.EqualsNotNull,
    ScalarOperationType.EqualsNotNull
  ];
  return validOptions.includes(option);
};
