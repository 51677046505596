<template>
  <div class="flex">
    <div
      class="badge badge-sm rounded-md py-2.5 px-2"
      :class="getColorByStatus(value!)"
    >
      <span class="text-nowrap">{{ useFormatUnderscoreText(value) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ClaimStatus } from "~/graphql";

  const props = defineProps<{
    value?: string;
  }>();

  const getColorByStatus = (value: string) => {
    let color = "";
    switch (value) {
      case ClaimStatus.Accepted:
        color = "badge-success";
        break;
      case ClaimStatus.Rejected:
        color = "badge-error bg-error ";
        break;
      case ClaimStatus.Created:
        color = "badge-neutral bg-neutral ";
        break;
      case ClaimStatus.UnderReview:
        color = "badge-info bg-info ";
        break;
      default:
        color = "badge-success";
        break;
    }
    return color;
  };
</script>
