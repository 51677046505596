<template>
  <div
    v-if="value !== undefined"
    class="font-serif min-w-0"
    :class="buildClasses"
  >
    <span v-if="props.currency" class="truncate">
      {{ useMonetaryFormat(props.value, props.currency, false) }}
    </span>
    <span v-else class="truncate">
      {{ useMonetaryFormat(props.value, "", true) }}
    </span>
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script lang="ts">
  export default {
    name: "WBValueNumber"
  };
</script>

<script setup lang="ts">
  import type { ViewValueProps } from "@/utils";

  const props = withDefaults(defineProps<ViewValueProps<number>>(), {
    align: "right",
    width: 150
  });

  const buildClasses = computed(() => {
    let classes = [];
    if (props.align == "right") {
      classes.push("flex");
      classes.push("flex-row");
      classes.push("justify-end");
    }
    if (props.width && props.width > 0) {
      classes.push(`min-w-[${props.width}px]`);
      classes.push(`max-w-[${props.width}px]`);
      classes.push(`w-[${props.width}px]`);
    }

    return classes;
  });
</script>
