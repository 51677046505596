export const useNumberFormat = (num: number | null | undefined) => {
  if (!num || !Number.isFinite(num)) return "";
  return Intl.NumberFormat().format(num);
};

export const useMonetaryFormat = (
  num: number | null | undefined,
  ccy: string,
  skipCurrency: boolean = false
) => {
  const _ccy = !skipCurrency ? " " + ccy : "";

  if (num !== null && num !== undefined && !Number.isFinite(num)) {
    return " -" + _ccy;
  }

  //TODO: get digits from market
  const digits = skipCurrency ? 0 : 2;

  const ret = `${Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(num as number)} ${_ccy}`;

  return ret;
};
