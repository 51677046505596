<template>
  <div v-if="value !== undefined" class="relative">
    <div
      class="p-0 my-[2px] badge badge-sm rounded-md relative overflow-hidden"
      :class="getColorByStatus(value!)"
      :style="getHeight"
    >
      <div class="absolute bg-white w-full h-full" />
      <div
        class="py-3 px-2 border-none text-[12px] font-normal w-full z-[1] flex items-center"
        :class="getColorByStatus(value!)"
        style="text-transform: capitalize"
      >
        <span>{{ capitalizeValue }}</span>
        <span
          v-if="value === PolicyStatusRt.Paid"
          style="line-height: 20px"
          class="relative -top-[1px] ml-1.5 text-[14px]"
          @mouseenter="showTooltip"
          @mouseleave="hideTooltip"
        >
          ⚠️
        </span>
      </div>
    </div>
    <div
      v-if="tooltip"
      class="relative -top-5 right-4 tooltip tooltip-warning tooltip-open"
      data-tip="Policy issue has failed"
    ></div>
  </div>
  <div v-else class="h-5 w-full grow animate-pulse bg-base-200"></div>
</template>

<script lang="ts">
  export default {
    name: "WBValueStatus"
  };
</script>

<script setup lang="ts">
  import { PolicyStatusRt } from "~/graphql";

  const props = withDefaults(
    defineProps<{
      value?: string;
      height?: number;
    }>(),
    { height: 22 }
  );

  const getHeight = computed(() => `height: ${props.height}px`);

  const getColorByStatus = (value: string) => {
    let color = "";
    switch (value) {
      case PolicyStatusRt.InEffect:
        color = "badge-success";
        break;
      case PolicyStatusRt.Issued:
        color = "badge-success bg-success/70 ";
        break;
      case PolicyStatusRt.Paid:
        color = "badge-info";
        break;
      case PolicyStatusRt.Accepted:
        color = "badge-info bg-info/70";
        break;
      case PolicyStatusRt.Acquired:
        color = "badge-info bg-info/40";
        break;
      case PolicyStatusRt.Acquiring:
        color = "badge-info bg-info/20";
        break;
      case PolicyStatusRt.Rejected:
        color = "badge-error";
        break;
      case PolicyStatusRt.Cancelled:
        color = "badge-error bg-error/50";
        break;
      case PolicyStatusRt.Expired:
        color = "badge-warning";
        break;
      case PolicyStatusRt.Abandoned:
        color = "badge-warning bg-warning/70";
        break;
      default:
        color = "badge-success";
        break;
    }
    return color;
  };

  const capitalizeValue = computed(() => {
    return props.value?.toLocaleLowerCase().replaceAll("_", " ");
  });

  const tooltip = ref(false); //we need this cause badge has overflow-hidden
  const showTooltip = () => {
    tooltip.value = true;
    setTimeout(() => hideTooltip(), 2000);
  };
  const hideTooltip = () => (tooltip.value = false);
</script>
