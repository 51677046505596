<template>
  <div v-if="props.value" class="flex items-center justify-center w-full">
    <Icon name="ic:baseline-check" class="text-success text-lg" />
  </div>
</template>

<script lang="ts">
  export default {
    name: "WBValueCheckbox"
  };
</script>

<script setup lang="ts">
  const props = defineProps<{
    value?: boolean;
  }>();
</script>
