//@ts-ignore
import getSession from "~~/graphql/getSession.gql";
//@ts-ignore
import findDistributor from "~~/graphql/findDistributor.gql";
import {
  type User,
  type Tenant,
  TenantType,
  type Distributor
} from "~~/graphql";
import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", () => {
  const user = ref<User | null>(null);
  const tenant = ref<Tenant | null>(null);
  const initialized = ref(false);

  async function initSession() {
    const { clients } = useApollo();
    const resp = await clients!["default"]!.query({
      query: getSession,
      fetchPolicy: "no-cache",
      context: {
        headers: {
          "X-Requested-With": "JavaScript"
        }
      }
    });

    if (resp) {
      user.value = resp.data.user;
      tenant.value = resp.data.tenant;

      if (tenant.value?.type === TenantType.Distributor) {
        const resp = await clients!["default"]!.query({
          query: findDistributor,
          variables: { id: tenant.value.id },
          fetchPolicy: "no-cache",
          context: {
            headers: {
              "X-Requested-With": "JavaScript"
            }
          }
        });

        (tenant.value as Distributor).channels = (
          resp.data.distributor as Distributor
        ).channels;
      }
    }
    initialized.value = true;
  }

  return { user, tenant, initialized, initSession };
});
